:root {
  --vk-blue: #4a76a8;
  --vk-light-blue: #5181b8;
  --vk-bg: #edeef0;
  --vk-text: #000000;
  --vk-light-text: #828282;
  --vk-white: #ffffff;
  --vk-light-gray: #f0f2f5;
  --vk-shadow: 0 1px 0 0 #d7d8db, 0 0 0 1px #e3e4e8;
  --font-size-small: 0.8125rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.1875rem;
  --spacing-small: 0.5rem;
  --spacing-medium: 1rem;
  --spacing-large: 1.25rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue",
    Geneva, "Noto Sans Armenian", "Noto Sans Bengali", "Noto Sans Cherokee",
    "Noto Sans Devanagari", "Noto Sans Ethiopic", "Noto Sans Georgian",
    "Noto Sans Hebrew", "Noto Sans Kannada", "Noto Sans Khmer", "Noto Sans Lao",
    "Noto Sans Osmanya", "Noto Sans Tamil", "Noto Sans Telugu", "Noto Sans Thai",
    sans-serif;
  line-height: 1.154;
  font-weight: 400;
  color: var(--vk-text);
  background-color: var(--vk-bg);
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  min-height: 100vh;
  max-width: 75rem;
  margin: 0 auto;
  padding: var(--spacing-large);
  box-sizing: border-box;
}

.contract-container,
.analysis-container {
  padding: var(--spacing-large);
  background-color: var(--vk-white);
  border-radius: 0.25rem;
  box-shadow: var(--vk-shadow);
  overflow-y: auto;
  max-height: calc(100vh - 2 * var(--spacing-large));
  box-sizing: border-box;
}

.contract-container {
  flex: 0 0 65%;
  margin-right: var(--spacing-large);
}

.analysis-container {
  flex: 0 0 calc(35% - var(--spacing-large));
}

.contract-text {
  font-size: var(--font-size-small);
  line-height: 1.462;
}

h2 {
  color: var(--vk-text);
  font-size: var(--font-size-large);
  margin-bottom: var(--spacing-large);
}

h3 {
  color: var(--vk-text);
  font-size: var(--font-size-medium);
  margin-bottom: var(--spacing-medium);
}

[class^="highlight-"] {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

[class^="highlight-"].active {
  background-color: #faebcc !important;
  padding: 2px 0;
}

[class*="highlight-"].active,
[class*="highlight-"].active * {
  background-color: #faebcc !important;
  padding: 2px 0;
}

.risk-summary {
  margin-bottom: var(--spacing-large);
}

.risk-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
}

.risk-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-small) var(--spacing-medium);
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: var(--font-size-small);
  background-color: var(--vk-light-gray);
  color: var(--vk-text);
  text-align: left;
}

.risk-item:hover {
  background-color: #e7e8ec;
}

.risk-item.active {
  background-color: var(--vk-light-blue);
  color: var(--vk-white);
}

.risk-detected .risk-indicator {
  color: #e64646;
}

.no-risk .risk-indicator {
  color: #4bb34b;
}

.criterion-card {
  background-color: var(--vk-white);
  border-radius: 0.25rem;
  padding: var(--spacing-large);
  margin-bottom: var(--spacing-large);
  box-shadow: var(--vk-shadow);
}

.risk-status {
  display: flex;
  align-items: center;
  padding: var(--spacing-small);
  border-radius: 0.25rem;
  margin-bottom: var(--spacing-medium);
  font-weight: 500;
  font-size: var(--font-size-small);
}

.risk-icon {
  margin-right: var(--spacing-small);
  font-size: 1.2em;
}

.reasoning {
  margin-bottom: var(--spacing-large);
  font-size: var(--font-size-small);
}

.clauses {
  display: flex;
  flex-direction: column;
}

.clause-button {
  background: none;
  border: none;
  text-align: left;
  color: var(--vk-blue);
  cursor: pointer;
  padding: var(--spacing-small) 0;
  margin: 0.25rem 0;
  transition: all 0.3s ease;
  font-size: var(--font-size-small);
}

.clause-button:hover,
.clause-button.active {
  text-decoration: underline;
  color: var(--vk-light-blue);
}

@media (max-width: 48rem) {
  .app {
    flex-direction: column;
    padding: var(--spacing-medium);
  }

  .contract-container,
  .analysis-container {
    flex: 1 1 auto;
    margin-right: 0;
    margin-bottom: var(--spacing-large);
    max-height: none;
  }
}
